<template>
  <div class="img-list">
    <div
      class="img-item"
      v-for="(item, index) in mgList"
      :key="item.img + index"
    >
      <div class="myimg">
        <div :id="'player' + item.img"></div>
      </div>
      <div class="myname">{{ index + 1 + "." + item.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [],
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      mgList: [
        {
          img: "73e14fa2175f50f9b0d53b3fcbcd7623_7",
          name: "篮子策略调用自定义公式使用教程",
        },
        {
          img: "73e14fa21771f1ddcc85c5f9c64a7c65_7",
          name: "单维策略调用自定义公式下单教程",
        },
        { img: "73e14fa2171302d489e4740093e96b9b_7", name: "单维策略使用教程" },
        {
          img: "73e14fa217bab00db441e34c62fcd734_7",
          name: "席位龙虎榜使用教程",
        },
        {
          img: "73e14fa217068f9314dfc25212257910_7",
          name: "公式页签模板创建使用教程",
        },
        { img: "73e14fa217c3423d850f571b011bc9ca_7", name: "篮子策略使用教程" },
        { img: "73e14fa2174e2c4c86e9a81f09b0aa47_7", name:"手机监控视频教程"}
      ],
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.loadPlayerScript(this.loadPlayer);
    });
  },
  methods: {
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    loadPlayer() {
      const polyvPlayer = window.polyvPlayer;

      this.mgList.forEach((item) => {
        this.player = polyvPlayer({
			wrap: "#player" + item.img,
			width: 360,
			height: 203,
			vid: item.img,
			cover_opacity:20,
        });
      });
    },
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>
<style lang="less" scoped>
.img-list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-row-gap: 82px;
  grid-column-gap: 5%;
  padding: 65px 0;
  box-sizing: border-box;
  .img-item {
    width: 100%;
    // padding-bottom: 82px;
    .myimg {
      width: 100%;
      img {
        width: 100%;
        display: block;
      }
    }
    .myname {
      width: 100%;
      height: 46px;
      background-color: #f5f4f9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>